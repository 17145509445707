import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FooterWaves from '../components/footer-waves';

function Agb() {
  return (
    <Layout>
      <SEO title="Allgemeine Geschäftsbedingungen" />

      <section className="bg-white py-8 mt-10 p-6">
        <div className="container max-w-5xl mx-auto m-8 text-gray-800">
          <h1 className="">Allgemeine Geschäftsbedingungen</h1>

          <h2>1. Grundlagen</h2>
          <p>
            Unsere Allgemeinen Geschäftsbedingungen, AGB, definieren die Rechte
            und Pflichten einer Zusammenarbeit zwischen uns, Novalab, und Ihnen,
            dem Auftraggeber. Die Zusammenarbeit kommt zustande, indem Sie uns
            mündlich oder schriftlich einen Auftrag erteilen. Wir verpflichten
            uns, Sie in diesem Moment über unsere AGB zu informieren und Ihnen
            diese zugänglich zu machen.
          </p>
          <p>
            Zu den Vereinbarungen unserer Zusammenarbeit gehören ausschliesslich
            unsere AGB und allenfalls eine von uns erstellte Offerte. Ihre
            eigenen Allgemeinen Geschäftsbedingungen haben keinen Einfluss auf
            unsere Zusammenarbeit. Widerspricht eine Angabe auf unserer Offerte
            den AGB, so gilt die Angabe auf der Offerte.
          </p>
          <p>
            Die Zusammenarbeit endet, sobald wir die offerierten Arbeiten
            abgeschlossen haben und Sie unsere Rechnungen vollständig beglichen
            haben. Abonnements, wie zum Beispiel das Hosting, haben eine fest
            definierte Laufzeit und können 30 Tage vor Anbruch einer neuen
            Periode von beiden Parteien schriftlich gekündigt werden. Ohne
            Kündigung erneuert sich ein Abonnement automatisch um die genannte
            Laufzeit.
          </p>
          <p>
            Wird unsere Zusammenarbeit aus irgendwelchen Gründen abgebrochen, so
            behalten wir das Eigentums- und Nutzrecht an den bereits geleisteten
            Arbeiten. Die Kosten für bereits geleistete Arbeit werden in
            Rechnung gestellt. Wir haben das Recht, unsere AGB jederzeit zu
            ändern. Sollte sich für Sie dadurch etwas an einer bestehenden
            Zusammenarbeit ändern, so haben Sie das Recht, innerhalb von 30
            Tagen nach Bekanntgabe der neuen AGB die Zusammenarbeit zu kündigen.
          </p>
          <p>
            Eine aktuelle Version unserer AGB können Sie jederzeit online
            einsehen unter www.novalab.ch/agb
          </p>
          <h2>2. Pflichten bei der Zusammenarbeit</h2>
          <p>
            Wir verpflichten uns, alle offerierten Arbeiten termingerecht
            auszuführen. Damit vereinbarte Termine eingehalten werden können
            erwarten wir auch von Ihnen, dass Sie uns rechtzeitig benötigtes
            Material (zum Beispiel Fotos, Texte, usw.) zur Verfügung stellen. Am
            vereinbarten Datum für den Beginn der Arbeiten müssen uns alle
            Inhalte zur Verfügung stehen. Bei grafischen Arbeiten (Logo-Design,
            Layout Design von Flyer, Websites, etc.) haben Sie das Recht auf
            eine (1) kostenlose Revision. Weitere Revisionen gehen auf Ihre
            Kosten.
          </p>
          <p>
            Nach Ihrer schriftlichen oder mündlichen Genehmigung des „Gut zum
            Druck“, „Gut zum Web“ oder ähnlichem „Gut zu...“, tragen Sie die
            volle Verantwortung für die formale sowie die inhaltliche
            Erscheinung des in Auftrag gegeben Produktes.
          </p>
          <h2>3. Rechnungsstellung und Zahlungsmodalitäten</h2>
          <p>
            Offerierte aber nicht geleistete Arbeiten werden nicht verrechnet.
            Bei nicht kalkuliertem und nicht offeriertem Mehraufwand werden Sie
            vor der Umsetzung informiert.
          </p>
          <p>
            Wir behalten uns das Recht vor, die fertigen Arbeiten erst
            auszuliefern, wenn unsere Rechnungen vollständig beglichen sind. Bei
            Abonnements (zum Beispiel beim Hosting) sind wir ausserdem
            berechtigt, unsere Leistungen einzuschränken oder ganz zu
            unterlassen, wenn Sie mit Zahlungen im Verzug sind.
          </p>
          <p>Unsere Preise beinhalten keine Mehrwertsteuer.</p>
          <h2>4. Mängel und Garantie</h2>
          <p>
            Reklamationen sind generell innert 10 Tagen nach Erhalt der Arbeiten
            oder Produkte schriftlich an uns zu richten. Reklamationen bei
            Leistungen Dritter, zu deren Beschaffung wir lediglich als
            Vermittler aufgetreten sind, liegen nicht in unserer Verantwortung.
            Wir setzen uns in diesem Falle als Vermittler für eine faire
            Regelung zwischen dem Kunden und Dritten ein, können jedoch für
            allfällig entstandene Schäden nicht belangt werden.
          </p>
          <p>
            Hat eine von uns erstellte Software Funktionsfehler (Bugs), so
            beheben wir diese innerhalb von 2 Jahren nach Abschluss der Arbeiten
            kostenlos. Nicht unter diese Garantie fallen Probleme, die im
            Zusammenhang mit Software- und System-Updates entstehen. Wird der
            Quellcode der Software vom Auftraggeber oder von Dritten verändert,
            so erlischt die Garantie.
          </p>
          <p>
            Leichte Abweichungen im Design und Layout beim Betrachten einer
            Website in verschiedenen Browsern und Geräten sind üblich und müssen
            hingenommen werden. Wenn nicht anders vereinbart testen wir neu
            erstellte Webseiten jeweils auf der neusten Version (Stichtag ist
            das Datum, an dem die Offerte unterschrieben wurde) der folgenden
            Browser:
          </p>
          <p>
            Mozilla Firefox, Google Chrome, Apple Safari. Spezifische Browser
            und Geräte werden nur berücksichtigt, wenn diese explizit in einer
            Vereinbarung erwähnt werden.
          </p>
          <p>
            Bei Leistungen die von Dritten abhängig sind, zum Beispiel die
            Optimierung einer Website für Suchmaschinen, können wir keine
            Garantie auf Erfolg geben.
          </p>
          <h2>5. Urheber- und Nutzungsrechte</h2>
          <p>
            Gemäss Schweizerischem Urheberrecht bleiben wir im Besitz des
            geistigen Eigentums an den Werken, die wir erstellen. Nach
            vollständiger Bezahlung unserer Rechnungen erhalten Sie das einfache
            Nutzungsrecht an diesen Werken. Die Nutzungsrechte sind zeitlich und
            geografisch unbegrenzt, jedoch nicht auf Dritte übertragbar. Als
            Werke zählen insbesondere Fotos, Grafiken, Websites, Software,
            Klänge, Layouts, Designs, Entwürfe und Konzepte. Handelt es sich bei
            den Werken um Internet-basierte Software, so beschränkt sich Ihr
            Nutzungsrecht auf die in der Offerte genannte(n) Domain(s).
          </p>
          <p>
            Handelt es sich bei den Werken um Software, so erhalten Sie auf
            Wunsch den für Sie entwickelten Quellcode. Davon ausgeschlossen ist
            Quellcode, den wir auf eigene Kosten oder für andere Projekte
            entwickelt haben (zum Beispiel ein Plugin oder ein Framework). Sie
            haben kein Recht, unsere Software zu vervielfältigen oder Dritten
            zugänglich zu machen.
          </p>
          <p>
            Für alle geleisteten Arbeiten behalten wir das Recht, diese als
            Referenzen mit Bild und Text öffentlich zu zeigen. Davon
            ausgeschlossen sind vertrauliche und nicht öffentliche Daten.
          </p>
          <p>
            Von den hier genannten Bestimmungen ausgeschlossen sind Werke
            Dritter. Verwenden wir in den Arbeiten zu Ihrem Auftrag zum Beispiel
            Schriften, Icons, Programme oder Fotografien von Dritten, so bleiben
            die Rechte dieser Werke beim jeweiligen Eigentümer.
          </p>
          <h2>6. Haftung</h2>
          <p>
            Sie verzichten auf jegliche Art von Haftungsansprüchen uns
            gegenüber. Eine Ausnahme bilden grobe Fahrlässigkeit oder Vorsatz
            unsererseits, wobei dann der Anspruch einzig auf den unmittelbar
            erlittenen Schaden und den Gegenwert der beanspruchten Leistungen
            beschränkt ist.
          </p>
          <p>
            Für Inhalte, die Sie uns liefern oder selber mit unserer Hilfe
            publizieren, haften Sie selber. Dazu gehören auch Links auf andere
            Websites.
          </p>
          <p>
            Wir informieren Sie nach bestem Wissen und Gewissen über rechtliche
            Aspekte wie Impressumspflicht, Datenschutz, DSGVO, usw. Diese
            Information darf nicht als Rechtsberatung missverstanden werden. Wir
            sind weder Juristen noch Datenschutz-Experten. Empfehlungen und
            Umsetzung durch Novalab garantieren keine Rechtssicherheit. Bitte
            beauftragen Sie in rechtlichen Fragen einen Anwalt.
          </p>
          <p>
            Bei der Programmierung legen wir grossen Wert auf Sicherheit und
            Funktionalität. Wir können jedoch keine Haftung übernehmen, wenn
            unsere Dienste nicht jederzeit verfügbar sind. Speziell wenn der
            Ausfall durch Dritte verursacht wird (Ausfall beim Hosting- oder
            Internet-Provider, Hackerangriff, usw.).
          </p>
          <h2>7. Salvatorische Klausel</h2>
          <p>
            Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise nichtig
            und/oder unwirksam sein, bleibt die Gültigkeit und/oder Wirksamkeit
            der übrigen Bestimmungen oder Teile solcher Bestimmungen unberührt.
            Die ungültigen und/oder unwirksamen Bestimmungen werden durch solche
            ersetzt, die dem Sinn und Zweck der ungültigen und/oder unwirksamen
            Bestimmungen in rechtwirksamer Weise wirtschaftlich am nächsten
            kommen. Das gleiche gilt bei eventuellen Lücken der Regelung.
          </p>
          <p className="mt-12">
            Die AGB unterstehen schweizerischem Recht mit unserem Geschäftssitz
            als ausschliesslichem Gerichtsstand.
          </p>
        </div>
      </section>

      <FooterWaves />
    </Layout>
  );
}

export default Agb;
